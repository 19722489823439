



import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./common/store";
import { PrivateRoute } from "./components/routing/PrivateRoute";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/css/material-ui/configTheme";

const SignIn = lazy(() => import('./components/auth/SignIn'));
const ProForm = lazy(() => import('./components/views/pro/registration_form/ProForm'));
const BecomePro = lazy(() => import('./components/views/pro/devenir_pro/BecomePro'));
const TermsAndConditions = lazy(() => import('./components/views/pro/devenir_pro/TermsAndConditions'));
const RequestListPro = lazy(() => import('./components/views/pro/request/Index'));
const History = lazy(() => import('./components/views/pro/history/Index'));
const Help = lazy(() => import('./components/views/pro/help/Help'));
const Activities = lazy(() => import('./components/views/pro/activity/Index'));
const Info = lazy(() => import('./components/views/pro/personall_data/Index'));
const ConfigInfoPro = lazy(() => import('./components/views/pro/first_login_process/ConfigInfoPro'));

const InfoClient = lazy(() => import('./components/views/client/personall_data/Index'));
const RequestListClient = lazy(() => import('./components/views/client/request/Index'));
const HistoryClient = lazy(() => import('./components/views/client/history/Index'));
const HelpClient = lazy(() => import('./components/views/client/assistance/Index'));

const ClientHome = lazy(() => import('./components/views/client/quote_generator/pages/ClientHome'));
const ChooseService = lazy(() => import('./components/views/client/quote_generator/pages/ChooseService'));
const Localisation = lazy(() => import('./components/views/client/quote_generator/pages/Localisation'));
const ThankYouPage = lazy(() => import('./components/views/client/quote_generator/pages/ThankYouPage'));
const SmsValidation = lazy(() => import('./components/views/client/quote_generator/pages/SmsValidation'));
const Anotation = lazy(() => import('./components/views/client/assistance/Anotation'));

const ErrorPage = lazy(() => import('./components/views/ErrorPage'));
const Welcome = lazy(() => import('./components/views/Welcome'));
const ResetPasswordForm = lazy(() => import('./components/auth/ResetPasswordForm'));


function App() {

    console.log("Dashboard Nov-07-2024 - 14:26");

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <div className="App">
                        <Suspense fallback={<div></div>}>
                            <Routes>
                                <Route exact path="/todoo/config-info-pro" element={<PrivateRoute authenticated={true} Component={<ConfigInfoPro />} path="/todoo/config-info-pro" />} />
                                <Route exact path="/todoo/reservations-list/:idR/:statusR" element={<PrivateRoute authenticated={true} Component={<RequestListPro />} path="/todoo/reservations-list/:idR/:statusR" />} />
                                <Route exact path="/todoo/reservations-list" element={<PrivateRoute authenticated={true} Component={<RequestListPro />} path="/todoo/reservations-list" />} />
                                <Route exact path="/todoo/activity" element={<PrivateRoute authenticated={true} Component={<Activities />} path="/todoo/activity" />} />
                                <Route exact path="/todoo/history" element={<PrivateRoute authenticated={true} Component={<History />} path="/todoo/history" />} />
                                <Route exact path="/todoo/personal_data/:lan" element={<PrivateRoute authenticated={true} Component={<Info />} path="/todoo/personal_data/:lan" />} />
                                <Route exact path="/todoo/personal_data" element={<PrivateRoute authenticated={true} Component={<Info />} path="/todoo/personal_data" />} />
                                <Route exact path="/todoo/help" element={<PrivateRoute authenticated={true} Component={<Help />} path="/todoo/help" />} />
                                <Route exact path="/todoo/help/:tabVal" element={<PrivateRoute authenticated={true} Component={<Help />} path="/todoo/help/:tabVal" />} />
                                <Route exact path="/todoo/welcome" element={<PrivateRoute authenticated={true} Component={<Welcome />} path="/todoo/welcome" />} />
                                <Route exact path="/reset-password-form/:token/:email" element={<ResetPasswordForm userType="pro" />} />
                                <Route exact path="/reset-password-form-client/:token/:email" element={<ResetPasswordForm userType="client" />} />
                                <Route exact path="/login" element={<SignIn />} />
                                <Route exact path="/recrutement" element={<ProForm />} />
                                <Route exact path="/terms" element={<TermsAndConditions />} />
                                <Route exact path="/todoo" element={<BecomePro />} />
                                <Route exact path="/client/localisation" element={<Localisation />} />

                                <Route
                                    exact
                                    path="/client/housing"
                                    element={<ChooseService />}
                                />

                                <Route
                                    exact
                                    path="/client/benefit"
                                    element={<ChooseService />}
                                />

                                <Route
                                    exact
                                    path="/client/validation"
                                    element={<ChooseService />}
                                />

                                <Route
                                    exact
                                    path="/client/thankyou"
                                    element={<ThankYouPage />}
                                />

                                <Route
                                    exact
                                    path="/client/sms_validation"
                                    element={<SmsValidation />}
                                />

                                <Route
                                    exact
                                    path="/"
                                    element={<ClientHome />}
                                />

                                <Route
                                    exact
                                    path="/client-comment-pro/:clientId/:prId/:date"
                                    element={<Anotation />}
                                />

                                <Route
                                    exact
                                    path="/client/personal_data"
                                    element={<PrivateRoute authenticated={true} Component={<InfoClient />} path="/client/personal_data" />}
                                />

                                <Route
                                    exact
                                    path="/client/request-list"
                                    element={<PrivateRoute authenticated={true} Component={<RequestListClient />} path="/client/request-list" />}
                                />

                                <Route
                                    exact
                                    path="/client/history"
                                    element={<PrivateRoute authenticated={true} Component={<HistoryClient />} path="/client/history" />}
                                />

                                <Route
                                    exact
                                    path="/client/help"
                                    element={<PrivateRoute authenticated={true} Component={<HelpClient />} path="/client/help" />}
                                />

                                <Route
                                    exact
                                    path="/client/help/:tabVal"
                                    element={<PrivateRoute authenticated={true} Component={<HelpClient />} path="/client/help/:tabVal" />}
                                />

                                <Route
                                    exact
                                    path="/login"
                                    element={<SignIn />}
                                />
                                <Route
                                    exact
                                    path="/errorPage"
                                    element={<ErrorPage />}
                                />
                                <Route
                                    exact
                                    path="*"
                                    element={<ErrorPage />}
                                />
                            </Routes>
                        </Suspense>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
}
export default App;